import { RTL_LANGUAGES } from './constants'
import type { Language } from '@wix/thunderbolt-becky-types'

export const isRTL = (language: Language) => RTL_LANGUAGES.includes(extractLanguageCode(language))

export const getDirection = (language: Language) => (isRTL(language) ? 'rtl' : 'ltr')

const extractLanguageCode = (language: Language) => {
	return language && language.split('-')[0]
}
